import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql, Link } from 'gatsby'

import './styles.scss'

const ProjectPreview = ({ blok }) => {
  const location = useLocation()
  const projectSlug = location.pathname.split('/')[2]

  const descriptionStyle = {
    textAlign: blok.description_alignment === 'left' ? 'left' : 'justify',
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="project-preview">
        <h1 className="project-preview__title">{blok.display_title}</h1>
        <div className="project-preview__content main__padded">
          <div
            className="project-preview__description"
            style={descriptionStyle}
          >
            {render(blok.description)}
          </div>
          <div className="project-preview__enter">
            <Link
              className="project-preview__enter__link"
              to={`/projects/${projectSlug}`}
            >
              View Project
            </Link>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default ProjectPreview
